body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.profileBtn .btn-primary {
  background-color: transparent;
  border: 0px;
  box-shadow: none !important;
}
.profileBtn > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border: 0px;
  box-shadow: none !important;
}

/* .clickBtn > .btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #0d6efd !important;
} */

.row.display-flex > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.tab-btn .nav-link {
  font-size: 30px;
  color: #fff;
  background: #00000024;
}

.tab-btn button:hover {
  color: #000;
}

.tab-btn button:active {
  color: #000 !important ;
}
.tab-btn {
  border-bottom: 3px solid #fff;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  text-align: center;
}

.profileBtn > .btn-check:active + .btn-primary,
.profileBtn > .btn-check:checked + .btn-primary,
.profileBtn > .btn-primary.active,
.profileBtn > .btn-primary:active,
.profileBtn > .btn-check:focus + .btn-primary,
.profileBtn > .btn-primary:focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #0d6efd;
}

.dropBtn {
  z-index: 0;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.rangeSelection {
  margin-right: 10px;
}
.dropBtn > .btn-primary.dropdown-toggle {
  background-color: #0d6efd !important;
}
